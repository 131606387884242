<template>
    <div class="smartHome">
        <headComp></headComp>
        <breadComp></breadComp>

        <div class="centerWrapper">
            <div class="banner">
                <el-carousel>
                    <el-carousel-item v-for="item in smartBannerList" :key="item.sort" @click.native="clickBannerImg(item.linkUrl)">
                        <!-- <img src="../assets/imgs/place44.png"/> -->
                        <img :style="{'cursor':item.linkUrl?'pointer':''}" :src="item.bannerImgUrl"/>
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="smartSpace">
                <!-- <div class="item">
                    <img src="../assets/imgs/place45.png"/>
                    <div class="text">智能客厅</div>
                </div>
                <div class="item">
                    <img src="../assets/imgs/place46.png"/>
                    <div class="text">智能卧室</div>
                </div>
                <div class="item">
                    <img src="../assets/imgs/place47.png"/>
                    <div class="text">智能浴室</div>
                </div>
                <div class="item">
                    <img src="../assets/imgs/place48.png"/>
                    <div class="text">智能阳台</div>
                </div>
                <div class="item">
                    <img src="../assets/imgs/place49.png"/>
                    <div class="text">智能厨房</div>
                </div> -->

                <div class="item" v-for="(item,i) in typeCoverList" :key="i" @click="()=>{item.goodsTypeId}">
                    <div class="img">
                        <img :src="item.cover"/>
                    </div>
                    <div class="text">{{item.goodsTypeName}}</div>
                </div>
            </div>
            <div class="areaName">商品推荐</div>
            <div class="recommendGoods">
                <!-- <div class="item" v-for="(item,i) in goodsList" :key="i" @click="clickGoods(item)">
                    <div class="img">
                        <img :src="item.cover?item.cover:require('../assets/imgs/place11.png')"/>
                    </div>
                    <div class="price"><span>￥</span>{{item.ourShopPrice}}</div>
                    <div class="intro">
                        <span class="tag">【{{item.brandName}}】</span>
                        {{item.name}} 
                    </div>
                    <div class="rela">
                        <div>
                            <img src="../assets/imgs/solded.png"/>
                            <span class="num">{{item.sales+item.virtualSales}}</span>
                            <span>已售</span>
                        </div>
                        <div>
                            <img src="../assets/imgs/heat.png"/>
                            <span class="num">{{item.hotValue}}</span>
                            <span>热度</span>
                        </div>
                    </div>
                </div> -->
                
                <div class="item" v-for="(item,i) in goodsList" :key="i" @click="clickGoods(item)">
                    <div class="img">
                        <img :src="item.cover?item.cover:require('../assets/imgs/place11.png')"/>
                    </div>
                    <div class="price"><span>￥</span>{{item.ourShopPrice}}</div>
                    <div class="intro">
                        <span class="tag">【{{item.brandName}}】</span>
                        {{item.name}} 
                    </div>
                    <div class="rela">
                        <div>
                            <img src="../assets/imgs/solded.png"/>
                            <span class="num">{{item.sales+item.virtualSales}}</span>
                            <span>已售</span>
                        </div>
                        <div>
                            <img src="../assets/imgs/heat.png"/>
                            <span class="num">{{item.hotValue}}</span>
                            <span>热度</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tabLine">
                <div class="tabs">
                    <div :class="{'active':goodsTypeId==item.goodsTypeId}" v-for="(item,i) in typeList" :key="i" @click="clickType(item)">
                        {{item.goodsTypeName}}
                        <div class="border_effect">
                            <div class="border_top"></div>
                            <div class="border_right"></div>
                            <div class="border_bottom"></div>
                            <div class="border_left"></div>
                        </div>
                    </div>
                </div>
                <div class="more" v-show="false">
                    <!-- <img src="../assets/imgs/more1.png"/> -->
                    更多
                    <i class="el-icon-arrow-right"></i>
                </div>
            </div>
            <div class="typeGoods">
                <div class="item" v-for="(item,i) in typeGoodsList" :key="i" @click="clickGoods(item)">
                    <div class="img">
                        <img :src="item.cover?item.cover:require('../assets/imgs/place11.png')"/>
                    </div>
                    <div class="price">
                        <div class="left">
                            <span>￥</span>{{item.ourShopPrice}}
                            <span class="pick">唯选</span>
                        </div>
                        <div class="right">
                            {{item.brandName}}
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="intro">
                        <span class="tag">【{{item.brandName}}】</span>
                        {{item.name}} 
                    </div>
                </div>
            </div>
            <div class="areaName">相关品牌</div>
            <div class="brands">
                <div class="item" v-for="(item,i) in brandList" :key="i">
                    <!-- <img src="../assets/imgs/place50.png"/> -->
                    <img :src="item.brandLogo"/>
                </div>
            </div>
        </div>

        <footerComp></footerComp>
    </div>
</template>

<script>
import headComp from "@/components/headComp.vue"
import footerComp from "@/components/footerComp.vue"
import breadComp from "@/components/breadComp.vue"
import {getSmartHomeGoodsList,getSmartHomeBrandList,getGoodsTypeListChild,
getSmartHomeGoodsTypeCover,getWebsiteSetting} from "@/axios/api"
export default {
    components: {
        headComp,footerComp,breadComp
    },
    data(){
        return{
            typeCoverList:[],
            goodsList:[],
            typeList:[
                // {
                //     goodsTypeName:"冰箱",
                //     goodsTypeId:1,
                // },
                // {
                //     goodsTypeName:"空调",
                //     goodsTypeId:2,
                // },
                // {
                //     goodsTypeName:"洗衣机",
                //     goodsTypeId:3,
                // },
                // {
                //     goodsTypeName:"电视",
                //     goodsTypeId:4,
                // },
                // {
                //     goodsTypeName:"扫地机器人",
                //     goodsTypeId:5,
                // },
                // {
                //     goodsTypeName:"智能安防",
                //     goodsTypeId:6,
                // },
            ],
            goodsTypeId:1,
            typeGoodsList:[],//分类商品列表
            brandList:[],//品牌列表
            smartBannerList:[],//banner列表
        }
    },
    methods: {
        // 查询网站配置
		gainWebsiteSetting(){
			getWebsiteSetting({
				host:location.host,
				// host:"www.i-dian.com",
			}).then(res=>{
                // console.log("网站配置",res);
                if(res.code==200){
                    if(res.data.websiteLogo){
                        this.websiteLogo=res.data.websiteLogo;
                    }
                    if(res.data.bannerPlaceList){
                        res.data.bannerPlaceList.forEach(item=>{
                            if(item.bannerPlace==3){
                                this.smartBannerList=item.bannerSettingList
                            }
                        })
                    }
                }
			})
		},
        clickBannerImg(url){
			// // console.log(url)
			if(url){
				window.open(url,'_blank')
			}
		},
        clickGoods(item){
            this.$router.push({
                path:'/furnitureDetail',
                query:{goodsId:item.goodsId}
            })
        },
        clickType(item){
            this.goodsTypeId=item.goodsTypeId;
            this.gainGoodsByType(item.goodsTypeId)
        },
        // 获取分类信息
        gainTypeCover(){
            getSmartHomeGoodsTypeCover().then(res=>{
                // console.log("分类信息",res);
                if(res.code==200){
                    this.typeCoverList=res.data
                }
            })
        },
        // 获取商品列表
        gainSmartHomeGoods(){
            getSmartHomeGoodsList({goodsTypeId:""}).then(res=>{
                // console.log("智慧家居商品",res);
                if(res.code==200){
                    this.goodsList=res.data;
                }
            })
        },
        // 获取相关品牌
        gainBrandList(){
            getSmartHomeBrandList().then(res=>{
                // console.log("品牌列表",res);
                if(res.code==200){
                    this.brandList=res.data;
                }
            })
        },
        // 获取智慧家居子分类
        gainTypeList(){
            getGoodsTypeListChild({id:'542022003486789'}).then(res=>{
                // console.log("子分类列表",res);
                if(res.code==200){
                    this.typeList=res.data;
                    this.goodsTypeId=res.data[0].goodsTypeId;
                }
            })
        },
        // 根据分类查询商品
        gainGoodsByType(id){
            getSmartHomeGoodsList({goodsTypeId:id}).then(res=>{
                // console.log("分类商品列表",res);
                if(res.code==200){
                    this.typeGoodsList=res.data;
                }
            })
        }
    },
    created() {
        this.gainWebsiteSetting();
        this.gainTypeCover();
        this.gainSmartHomeGoods();
        this.gainBrandList();
        this.gainTypeList()
    },
}
</script>

<style lang="less" scoped>
.smartHome{
    .banner{
        margin-top: 40px;
        // height: 623px;
        // &>img{
        //     width: 100%;
        //     height: 100%;
        // }
        height: 623px;
        .el-carousel{
            height: 100%;
            /deep/.el-carousel__container{
                height: 100%;
            }
            /deep/.el-carousel__item{
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    .smartSpace{
        margin-top: 78px;
        display: grid;
        grid-template-columns: 507px 267px 267px 346px;
        grid-template-rows: 226px 215px;
        grid-row-gap: 11px;
        grid-column-gap: 14px;
        justify-content: space-between;
        .item{
            // background: #eee;
            position: relative;
            &>.img{
                width: 100%;
                height: 100%;
                overflow: hidden;
                position: relative;
                border-radius: 6px;
                &>img{
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    transition: all 0.3s;
                    object-fit: cover;
                    border-radius: 6px;
                }
            }
            .text{
                // width: 200px;
                // height: 56px;
                width: 158px;
                height: 52px;
                background: rgba(0, 0, 0, 0.8);
                background: rgba(0, 0, 0, 0.5);
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                font-size: 28px;
                font-size: 24px;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
            }
            &:nth-child(1){
                grid-row-start: 1;
                grid-row-end: 3;
                .text{
                    right: 0;
                    top: 0;
                    border-top-right-radius: 6px;
                }
            }
            &:nth-child(2){
                grid-row-start: 1;
                grid-row-end: 2;
                grid-column-start: 2;
                grid-column-end: 4;
                .text{
                    right: 0;
                    bottom: 0;
                    border-bottom-right-radius: 6px;
                }
            }
            &:nth-child(3){
                grid-row-start: 2;
                grid-row-end: 3;
                grid-column-start: 2;
                grid-column-end: 3;
                .text{
                    left: 0;
                    bottom: 0;
                    border-bottom-left-radius: 6px;
                }
            }
            &:nth-child(4){
                grid-row-start: 2;
                grid-row-end: 3;
                grid-column-start: 3;
                grid-column-end: 4;
                .text{
                    right: 0;
                    bottom: 0;
                    border-bottom-right-radius: 6px;
                }
            }
            &:nth-child(5){
                grid-row-start: 1;
                grid-row-end: 3;
                .text{
                    left: 0;
                    top: 0;
                    border-top-left-radius: 6px;
                }
            }

            &:hover{
                filter: brightness(0.7);
                .img{
                    &>img{
                        width: calc(100% + 20px);
                        height: calc(100% + 20px);
                    }
                }
            }
        }
    }
    .areaName{
        margin: 46px 0 0;
        font-size: 20px;
        font-weight: 500;
        color: #333;
    }
    .recommendGoods{
        // margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        .item{
            width: 337px;
            margin-top: 40px;
            margin-right: 40px;
            cursor: pointer;
            background: rgba(245, 245, 245, 1);
            padding-bottom: 10px;
            &:nth-child(4n){
                margin-right: 0;
            }
            &>.img{
                width: 337px;
                height: 252px;
                position: relative;
                overflow: hidden;
                border-radius: 6px 6px 0 0;
                &>img{
                    width: 337px;
                    height: 252px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    transition: all 0.3s;
                    object-fit: cover;
                    border-radius: 6px 6px 0 0;
                }
            }
            .price{
                font-size: 22px;
                font-weight: 700;
                color: rgba(200, 64, 57, 1);
                margin-top: 13px;
                padding: 0 10px;
                &>span{
                    font-size: 14px
                }
            }
            .intro{
                padding: 0 10px;
                font-size: 13px;
                font-weight: 700;
                letter-spacing: -0.7px;
                line-height: 20px;
                color: rgba(56, 56, 56, 1);
                margin-top: 6px;
                height: 40px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp:2;
                line-clamp:2;
                .tag{
                    font-size: 13px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    line-height: 20px;
                    color: rgba(158, 110, 62, 1);
                }
            }
            .rela{
                display: flex;
                align-items: center;
                margin-top: 10px;
                padding: 0 10px;
                &>div{
                    display: flex;
                    align-items: center;
                    font-size: 13px;
                    font-weight: 700;
                    color: rgba(153, 153, 153, 1);
                    margin-right: 15px;
                    padding-right: 15px;
                    border-right: 1px solid rgba(237, 237, 237, 1);
                    &:last-child{
                        border-right: none;
                        margin-right: 0;
                    }
                    .num{
                        font-size: 12px;
                        font-weight: 700;
                        color: rgba(230, 77, 0, 1);
                        margin: 0 4px;
                    }
                }
            }
            &:hover{
                .img{
                    img{
                        width: 357px;
                        height: 272px;
                    }
                }
            }
        }
    }
    .tabLine{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 90px;
        .tabs{
            height: 37px;
            display: flex;
            &>div{
                height: 100%;
                padding: 0 32px;
                display: flex;
                align-items: center;
                font-size: 18px;
                font-weight: 400;
                color: #333;
                // border: 3px solid transparent;
                border: 1px solid rgba(196, 196, 196, 1);
                box-sizing: border-box;
                cursor: pointer;
                position: relative;
                margin-right: 34px;
                &:last-child{
                    margin-right: 0;
                }
                &.active{
                    color: rgba(37, 126, 93, 1);
                    border-color: rgba(37, 126, 93, 1);
                    border-width: 2px;
                    .border_effect{
                        display: none;
                    }
                }
                .border_effect{
                    height: calc(100% + 6px);
                    width: calc(100% + 6px);
                    position: absolute;
                    left: -3px;
                    top: -3px;
                    &>div{
                        transition: all 0.3s linear;
                    }
                    .border_top{
                        position: absolute;
                        top: 0;
                        right: 0;
                        height: 3px;
                        width: 0;
                        background: rgba(37, 126, 93, 1)
                    }
                    .border_right{
                        position: absolute;
                        bottom: 0;
                        right: 0;
                        height: 0;
                        width: 3px;
                        background: rgba(37, 126, 93, 1)
                    }
                    .border_bottom{
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        height: 3px;
                        width: 0;
                        background: rgba(37, 126, 93, 1)
                    }
                    .border_left{
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 0;
                        width: 3px;
                        background: rgba(37, 126, 93, 1)
                    }
                }
                &:hover{
                    .border_top{
                        width: 100%;
                    }
                    .border_right{
                        height: 100%;
                    }
                    .border_bottom{
                        width: 100%;
                    }
                    .border_left{
                        height: 100%;
                    }
                }
            }
        }
        .more{
            cursor: pointer;
            width: 73px;
            height: 32px;
            border-radius: 24px;
            background: rgba(229, 229, 229, 1);
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-weight: 500;
            color: #333;
            padding-left: 5px;
            padding-bottom: 1px;
            i{
                // margin-left: 4px;
                // margin-top: 2px;
            }
            &:hover{
                background: rgba(225, 242, 236, 1);
                color: rgba(37, 126, 93, 1);
            }
        }
    }
    .typeGoods{
        // margin-top: 40px;
        display: flex;
        flex-wrap: wrap;
        .item{
            width: 337px;
            margin-top: 40px;
            margin-right: 40px;
            cursor: pointer;
            background: rgba(245, 245, 245, 1);
            padding-bottom: 10px;
            &:nth-child(4n){
                margin-right: 0;
            }
            &>.img{
                width: 337px;
                height: 252px;
                position: relative;
                overflow: hidden;
                border-radius: 6px 6px 0 0;
                &>img{
                    width: 337px;
                    height: 252px;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%,-50%);
                    transition: all 0.3s;
                    object-fit: cover;
                    border-radius: 6px 6px 0 0;
                }
            }
            .price{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 13px;
                padding: 0 10px;
                .left{
                    font-size: 22px;
                    font-weight: 700;
                    color: rgba(200, 64, 57, 1);
                    &>span{
                        font-size: 14px
                    }
                    .pick{
                        color: #DCB08E;
                        border: 0.5px solid #DCB08E;
                        margin-left: 10px;
                        padding: 2px 5px;
                    }
                }
                .right{
                    font-size: 12px;
                    color: #fff;
                    background: linear-gradient(to right,#FD9A27,#FD6F22);
                    border-radius: 10px;
                    height: 26px;
                    border-radius: 13px;
                    display: flex;
                    align-items: baseline;
                    padding: 4px 10px;
                    cursor: pointer;
                    &>i{
                        margin-left: 2px;
                    }
                }
            }
            .intro{
                padding: 0 10px;
                font-size: 13px;
                font-weight: 700;
                letter-spacing: -0.7px;
                line-height: 20px;
                color: rgba(56, 56, 56, 1);
                margin-top: 6px;
                height: 40px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp:2;
                line-clamp:2;
                .tag{
                    font-size: 13px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    line-height: 20px;
                    color: rgba(158, 110, 62, 1);
                }
            }
            &:hover{
                .img{
                    img{
                        width: 357px;
                        height: 272px;
                    }
                }
            }
        }
    }
    .brands{
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        .item{
            width: 343px;
            height: 100px;
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
}
</style>